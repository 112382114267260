import React from "react"
import { ReactComponent as OrangeBalls } from "../../../icons/Frame-3.svg"
import { ReactComponent as GreenBalls } from "../../../icons/Frame.svg"
import { ReactComponent as YellowBalls } from "../../../icons/Frame-2.svg"
import { ReactComponent as PurpleBalls } from "../../../icons/Frame-1.svg"
const TotalWon = () => {
  return (
    <div className="p-2 ">
      <div className="flex flex-col w-full p-2 bg-white rounded-lg">
        <div className="flex flex-col mb-6">
          <span className="text-gray-600">Won in the last game:</span>
          <span className="text-2xl font-bold">2,046,960 AED</span>
        </div>
        <div className="flex w-full">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M320 480c0 49.1 19.1 95.3 53.9 130.1 34.7 34.8 81 53.9 130.1 53.9h16c49.1 0 95.3-19.1 130.1-53.9 34.8-34.7 53.9-81 53.9-130.1V184H320v296zM184 352c0 41 26.9 75.8 64 87.6-37.1-11.9-64-46.7-64-87.6zm364 382.5C665 721.8 758.4 630.2 773.8 514 758.3 630.2 665 721.7 548 734.5zM250.2 514C265.6 630.2 359 721.8 476 734.5 359 721.7 265.7 630.2 250.2 514z"></path>
                <path d="M868 160h-92v-40c0-4.4-3.6-8-8-8H256c-4.4 0-8 3.6-8 8v40h-92a44 44 0 0 0-44 44v148c0 81.7 60 149.6 138.2 162C265.7 630.2 359 721.7 476 734.5v105.2H280c-17.7 0-32 14.3-32 32V904c0 4.4 3.6 8 8 8h512c4.4 0 8-3.6 8-8v-32.3c0-17.7-14.3-32-32-32H548V734.5C665 721.7 758.3 630.2 773.8 514 852 501.6 912 433.7 912 352V204a44 44 0 0 0-44-44zM248 439.6a91.99 91.99 0 0 1-64-87.6V232h64v207.6zM704 480c0 49.1-19.1 95.4-53.9 130.1-34.8 34.8-81 53.9-130.1 53.9h-16c-49.1 0-95.4-19.1-130.1-53.9-34.8-34.8-53.9-81-53.9-130.1V184h384v296zm136-128c0 41-26.9 75.8-64 87.6V232h64v120z"></path>
              </svg>
            </div>
            <div className="flex flex-col ml-1 leading-4 ">
              <span className="text-sm font-semibold">21 181</span>
              <span className="text-xs text-gray-600">Winners</span>
            </div>
          </div>
          <div className="flex items-center ml-4">
            <div className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M911.9 283.9v.5L835.5 865c-1 8-7.9 14-15.9 14H204.5c-8.1 0-14.9-6.1-16-14l-76.4-580.6v-.6 1.6L188.5 866c1.1 7.9 7.9 14 16 14h615.1c8 0 14.9-6 15.9-14l76.4-580.6c.1-.5.1-1 0-1.5z"></path>
                <path d="M773.6 810.6l53.9-409.4-139.8 86.1L512 252.9 336.3 487.3l-139.8-86.1 53.8 409.4h523.3zm-374.2-189c0-62.1 50.5-112.6 112.6-112.6s112.6 50.5 112.6 112.6v1c0 62.1-50.5 112.6-112.6 112.6s-112.6-50.5-112.6-112.6v-1z"></path>
                <path d="M512 734.2c61.9 0 112.3-50.2 112.6-112.1v-.5c0-62.1-50.5-112.6-112.6-112.6s-112.6 50.5-112.6 112.6v.5c.3 61.9 50.7 112.1 112.6 112.1zm0-160.9c26.6 0 48.2 21.6 48.2 48.3 0 26.6-21.6 48.3-48.2 48.3s-48.2-21.6-48.2-48.3c0-26.6 21.6-48.3 48.2-48.3z"></path>
                <path d="M188.5 865c1.1 7.9 7.9 14 16 14h615.1c8 0 14.9-6 15.9-14l76.4-580.6v-.5c.3-6.4-6.7-10.8-12.3-7.4L705 396.4 518.4 147.5a8.06 8.06 0 0 0-12.9 0L319 396.4 124.3 276.5c-5.5-3.4-12.6.9-12.2 7.3v.6L188.5 865zm147.8-377.7L512 252.9l175.7 234.4 139.8-86.1-53.9 409.4H250.3l-53.8-409.4 139.8 86.1z"></path>
              </svg>{" "}
            </div>
            <div className="flex flex-col ml-1 leading-4">
              <span className="text-sm font-semibold">2</span>
              <span className="text-xs text-gray-600">Millionaires</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-4">
            <div className="flex items-center justify-between w-full px-2 border-t border-b">
                <div className="flex items-center"> 
                <OrangeBalls className='w-16 h-16' />
                    <span className="ml-2 text-sm font-medium">4 Out Of 20</span>
                </div>
                <span className="font-bold">343,470 AED</span>
            </div>
            <div className="flex items-center justify-between w-full px-2 border-t border-b">
                <div className="flex items-center"> 
                <GreenBalls className='w-16 h-16' />
                    <span className="ml-2 text-sm font-medium">5 Out Of 36</span>
                </div>
                <span className="font-bold">465,640 AED</span>
            </div>
            <div className="flex items-center justify-between w-full px-2 border-t border-b">
                <div className="flex items-center"> 
                <YellowBalls className='w-16 h-16' />
                    <span className="ml-2 text-sm font-medium">6 Out Of 45</span>
                </div>
                <span className="font-bold">511,710 AED</span>
            </div>
            <div className="flex items-center justify-between w-full px-2 border-t border-b">
                <div className="flex items-center"> 
                <PurpleBalls className='w-16 h-16' />
                    <span className="ml-2 text-sm font-medium">7 Out Of 49</span>
                </div>
                <span className="font-bold">726,140 AED</span>
            </div>
        </div>
      </div>
    </div>
  )
}

export default TotalWon
