import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import RollingLine from './components/RollingLine';
import GamesSelect from './components/GamesSelect';
import LastWins from './components/LastWins';
import TotalWon from './components/TotalWon';
import Support from './components/Support';
import OurWinnder from './components/OurWinnder';
import AndCounting from './components/AndCounting';
import WaveBackground from './components/WaveBackground';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Home = () => {
  const query = useQuery();

  useEffect(() => {
    const pixelId = query.get('pixel'); 

    if (pixelId) {
      localStorage.setItem('facebookPixelId', pixelId);

      ReactPixel.init(pixelId);
      ReactPixel.pageView();
    } else {
    }
  }, [query]);

  return (
    <div className='flex flex-col'>
      <RollingLine />
      <GamesSelect />
      <AndCounting />
      <LastWins />
      <TotalWon />
      <OurWinnder />
      <Support />
    </div>
  );
};

export default Home;
