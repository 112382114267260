import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useCart } from "react-use-cart"
import { useLocation } from 'react-router-dom';  // Import useLocation from react-router-dom
import trackUserAction from "../../providers/track_action";
const Cart = () => {
  const {
    addItem,
    removeItem,
    updateItemQuantity,
    items,
    isEmpty,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()
  const location = useLocation();  // Get the current location


  const [show, set_show] = useState(false)

  useEffect(() => {
    console.log('Effect triggered');
    
    // Check the URL path and totalItems
    if (location.pathname === '/checkout') {
      set_show(false);  // Hide the component on the /checkout page
    }else if(location.pathname === '/'){
        set_show(false);
    } else if (totalItems > 0) {
      set_show(true);   // Show the component if there are no items
    } else {
      set_show(false);  // Hide the component otherwise
    }
  }, [totalItems, location.pathname]);  // Run the effect when totalItems or URL changes

  return (
    <div
      className={`fixed bottom-0 transition-all p-4 pb-8 pt-8 left-0   right-0 z-50  ${
        show ? '   opacity-100 pointer-events-auto'  : ' opacity-0 pointer-events-none'
      }`}
    >
        <div className="flex items-center justify-center w-full bg-[#D300FF] rounded-lg shadow">
            
        <Link onClick={()=>{trackUserAction(`User went to Checkout`, false)}}  to={'/checkout'} className="flex items-center justify-between w-full p-4 text-white">
        <span className="text-lg font-semibold">BUY {totalItems}                   {
                    totalItems > 1 ? ( 'TICKETS') : ('TICKET')
                  }</span>
        <span className="text-lg font-bold">{cartTotal}.00 AED</span>
        </Link>
        </div>
    </div>
  )
}

export default Cart
