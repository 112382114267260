import React from "react"
import { ReactComponent as OrangeBalls } from "../../../icons/Frame-3.svg"
import { ReactComponent as GreenBalls } from "../../../icons/Frame.svg"
import { ReactComponent as YellowBalls } from "../../../icons/Frame-2.svg"
import { ReactComponent as PurpleBalls } from "../../../icons/Frame-1.svg"
import GameComponent from "./GameComponent"

const GamesSelect = () => {
  return (      
    <div className="grid grid-cols-2 gap-2 px-2 ">
      <GameComponent link={4} left={16} from={120} color={'#FF8310'} text={'white'} price={5} prize={'100,000'}   icon={<OrangeBalls className="z-20 w-full h-fit aspect-square" />} />
      <GameComponent link={5} left={'1,634'} from={'34,000'} color={'#00FF83'} text={'black'} price={15} prize={'500,000'} icon={<GreenBalls className="z-20 w-full h-fit aspect-square" />} />
      <GameComponent link={6} left={'7,129'} from={'54,000'} color={'#FFFC00'} text={'black'} price={25} prize={'1,000,000'}   icon={<YellowBalls className="z-20 w-full h-fit aspect-square" />} />
      <GameComponent link={7} left={'9,738'} from={'100,000'} color={'#D300FF'} text={'white'}price={50}  prize={'25,000,000'}  icon={<PurpleBalls className="z-20 w-full h-fit aspect-square" />} />
    </div>
  )
}

export default GamesSelect
