import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import NavBar from "./pages/NavBar/NavBar";
import Footer from "./pages/Footer/Footer";
import WaveBackground from "./pages/Home/components/WaveBackground";
import Game6 from "./pages/Game6/Game6";
import { CartProvider } from "react-use-cart";
import Cart from "./pages/Cart/Cart";
import Game4 from "./pages/Game6/Game4";
import Game5 from "./pages/Game6/Game5";
import Game7 from "./pages/Game6/Game7";
import Checkout from "./pages/checkout/Checkout";
import trackUserAction from "./providers/track_action";
import UserStats from "./providers/UserStats";
import { PromoProvider } from "./providers/Init";
import ScrollToTop from "./providers/ScrollToTop";
import ReactPixel from 'react-facebook-pixel';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function App() {
  const [activeComponent, setActiveComponent] = useState('Details');
  const query = useQuery();

  useEffect(() => {
    const pixelId = query.get("pixel"); 
    if (pixelId) {
      const advancedMatching = {};
      const options = {
        autoConfig: true, 
        debug: false,     
      };
      
      ReactPixel.init(pixelId, advancedMatching, options);
      ReactPixel.pageView(); 
    }
  }, [query]);

  return (
    <PromoProvider>
      <div className="relative w-full min-h-lvh bg-gray-50">
        <NavBar />
        <ScrollToTop />
        <div className="h-[15rem] bg-blue-400">
          <WaveBackground />
        </div>
        <CartProvider>
          <Cart />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Game4" element={<Game4 />} />
            <Route path="/Game5" element={<Game5 />} />
            <Route path="/Game6" element={<Game6 />} />
            <Route path="/Game7" element={<Game7 />} />
            <Route
              path="/checkout"
              element={<Checkout activeComponent={activeComponent} setActiveComponent={setActiveComponent} />}
            />
            <Route path="/stats" element={<UserStats />} />
          </Routes>
        </CartProvider>
        <Footer />
      </div>
    </PromoProvider>
  );
}

export default App;
