import React, { useState, useEffect } from 'react';
import { useCart } from 'react-use-cart';
import classNames from 'classnames';

const TicketGame = ({out, of, color, price, type}) => {
  const numbers = Array.from({ length: of }, (_, i) => i + 1);

  const [tickets, setTickets] = useState([{ selectedNumbers: [] }]);

  const {
    addItem,
    removeItem,
    items,   // Check cart items
    inCart,
    emptyCart,   // Ensure this works properly
  } = useCart();

  useEffect(()=>{
    emptyCart()
  },[])
  // Handle number selection for a specific ticket
  const handleNumberClick = (ticketIndex, number) => {
    setTickets((prevTickets) =>
      prevTickets.map((ticket, index) => {
        if (index === ticketIndex) {
          // Toggle number selection for the selected ticket
          const isSelected = ticket.selectedNumbers.includes(number);
          const updatedNumbers = isSelected
            ? ticket.selectedNumbers.filter((num) => num !== number)
            : ticket.selectedNumbers.length < out
            ? [...ticket.selectedNumbers, number]
            : ticket.selectedNumbers;

          // Add console logs to debug
          console.log('Updated Numbers:', updatedNumbers);
          console.log('TicketIndex:', ticketIndex);
          
          const ticketId = ticketIndex + 1;  // TicketIndex + 1 to avoid 0 as ID
          const isInCart = items.some((item) => item.id === ticketId); // Use 'items' to check
          
          console.log('Is in Cart:', isInCart);

          if (updatedNumbers.length === out && !isInCart) {   // Test with 2 instead of 6
            console.log('Adding ticket to cart');
            addItem({ id: ticketId, type: type, price:price, name: `Ticket ${ticketId}`, numbers: updatedNumbers });
          } else if (updatedNumbers.length < out && isInCart) {  // Test with 2 instead of 6
            console.log('Removing ticket from cart');
            removeItem(ticketId);
          }

          return { ...ticket, selectedNumbers: updatedNumbers };
        }
        return ticket;
      })
    );
  };

  // Add a new ticket
  const addNewTicket = () => {
    setTickets([...tickets, { selectedNumbers: [] }]);
  };

  // Generate random numbers for a specific ticket
  const generateRandomNumbers = (ticketIndex) => {
    const randomNumbers = [];
    while (randomNumbers.length < out) {
      const randNum = Math.floor(Math.random() * of + 1);
      if (!randomNumbers.includes(randNum)) randomNumbers.push(randNum);
    }
    const ticketId = ticketIndex + 1; 
    const isInCart = items.some((item) => item.id === ticketId);
    
    if (randomNumbers.length === out && !isInCart) {   // Test with 2 instead of 6
        console.log('Adding ticket to cart');
        addItem({ id: ticketId,type: type, price:price, name: `Ticket ${ticketId}`, numbers: randomNumbers });
      }
    setTickets((prevTickets) =>
      prevTickets.map((ticket, index) =>
        index === ticketIndex
          ? { ...ticket, selectedNumbers: randomNumbers }
          : ticket
      )
    );
  };

  return (
    <div className="max-w-lg p-4 mx-auto bg-white rounded-lg shadow">
      {/* Render each ticket */}
      {tickets.map((ticket, ticketIndex) => (
        <div key={ticketIndex} className="mb-16">
          <h2 className="mb-4 text-lg font-bold">Ticket №{ticketIndex + 1}</h2>
          <p className="mb-2">Select a minimum of {out} numbers</p>

          {/* Progress Bar */}
          <div className="h-2 mb-4 bg-gray-200 rounded">
            <div
              className={`h-2 ${color} rounded`}
              style={{ width: `${(ticket.selectedNumbers.length / out) * 100}%` }}
            ></div>
          </div>

          {/* Number Grid */}
          <div className="grid grid-cols-5 gap-2">
            {numbers.map((number) => (
              <button
                key={number}
                className={`w-10 h-10 transition-all rounded-lg border-b-2 border-b-gray-300 flex items-center justify-center bg-gray-100 text-gray-300 font-medium ${!ticket.selectedNumbers.includes(number) ? 'bg-gray-100 text-gray-300' : (`${color} text-gray border-b-0`)}`}
                  
                onClick={() => handleNumberClick(ticketIndex, number)}
              >
                {number}
              </button>
            ))}
          </div>

          {/* Random Number Button */}
          <button
            className="w-full px-4 py-3 mt-4 font-medium text-black bg-gray-100 rounded-lg"
            onClick={() => generateRandomNumbers(ticketIndex)}
          >
            Random
          </button>
        </div>
      ))}

      {/* Add New Ticket Button */}


      <div className="relative flex items-center justify-center w-full add-item-card">
      <div className="w-full mb-6 blur-sm">
          <h2 className="mb-4 text-lg font-bold">Билет X</h2>
          <p className="mb-2">Select a minimum of {out} numbers</p>

          {/* Progress Bar */}
          <div className="h-2 mb-4 bg-gray-200 rounded">
            <div
              className="h-2 bg-yellow-400 rounded"
              style={{ width: `0%` }}
            ></div>
          </div>

          {/* Number Grid */}
          <div className="grid grid-cols-5 gap-2">
            {numbers.map((number) => (
              <button
                key={number}
                className=
                  'flex items-center justify-center w-10 h-10 font-medium text-gray-300 bg-gray-100 border-b-2 rounded-lg border-b-gray-300'>
                {number}
              </button>
            ))}
          </div>

          {/* Random Number Button */}
          <button
            className="px-4 py-2 mt-4 font-medium text-black bg-gray-300 rounded-lg"
          >
            Случайные числа
          </button>
        </div>
        <div className="absolute top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center">
            
        <button
          className="px-4 py-2 text-white bg-[#D300FF] rounded-full z-40"
          onClick={addNewTicket}
        >
          ➕ Add Ticket
        </button>
        </div>
      </div>


















      {/* Cart Section */}
      {/* <div className="mt-8">
        <h2 className="mb-4 text-lg font-bold">Корзина</h2>
        <div>
          {items.map((item) => (
            <div key={item.id} className="flex items-center justify-between">
              <span>{item.name}</span>
              <span>{item.numbers.join(', ')}</span>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default TicketGame;
