import React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as OrangeBalls } from "../../../icons/Frame-3.svg"
import { ReactComponent as GreenBalls } from "../../../icons/Frame.svg"
import { ReactComponent as YellowBalls } from "../../../icons/Frame-2.svg"
import { ReactComponent as PurpleBalls } from "../../../icons/Frame-1.svg"
const RollingLine = () => {
  const content = ['Message 1', 'Message 2', 'Message 3'];
  const wins = [
    {
      "id": 43117,
      "amount": "5,500",
      "icon": "<GreenBalls />"
    },
    {
      "id": 41450,
      "amount": "2,400",
      "icon": "<YellowBalls />"
    },
    {
      "id": 39583,
      "amount": "9,500",
      "icon": "<PurpleBalls />"
    },
    {
      "id": 42740,
      "amount": "3,100",
      "icon": "<YellowBalls />"
    },
    {
      "id": 39721,
      "amount": "1,500",
      "icon": "<YellowBalls />"
    },
    {
      "id": 41393,
      "amount": "6,000",
      "icon": "<GreenBalls />"
    },
    {
      "id": 41755,
      "amount": "4,500",
      "icon": "<GreenBalls />"
    },
    {
      "id": 39618,
      "amount": "8,500",
      "icon": "<PurpleBalls />"
    },
    {
      "id": 43206,
      "amount": "10,000",
      "icon": "<PurpleBalls />"
    },
    {
      "id": 41053,
      "amount": "7,000",
      "icon": "<GreenBalls />"
    },
    {
      "id": 39422,
      "amount": "8,000",
      "icon": "<PurpleBalls />"
    },
    {
      "id": 41585,
      "amount": "1,200",
      "icon": "<YellowBalls />"
    },
    {
      "id": 42909,
      "amount": "600",
      "icon": "<OrangeBalls />"
    },
    {
      "id": 43020,
      "amount": "500",
      "icon": "<OrangeBalls />"
    },
    {
      "id": 39954,
      "amount": "9,000",
      "icon": "<PurpleBalls />"
    }
  ]
  
  

  const renderIcon = (iconString) => {
    switch (iconString) {
      case "<OrangeBalls />":
        return <OrangeBalls className='w-12 h-12' />;
      case "<GreenBalls />":
        return <GreenBalls className='w-12 h-12' />;
      case "<YellowBalls />":
        return <YellowBalls className='w-12 h-12' />;
      case "<PurpleBalls />":
        return <PurpleBalls className='w-12 h-12' />;
      default:
        return null;
    }
  };
  return (
    <div className="flex items-center w-full h-16 overflow-hidden shadow bg-gray-white"> {/* Tailwind for container */}
      <motion.div
        className="flex whitespace-nowrap"
        animate={{ x: ['0%', '-100%'] }}  // Move from fully visible (0%) to hidden (-100%)
        transition={{ repeat: Infinity, duration: 200, ease: 'linear' }}
        initial={{ x: '0%' }} // Start at 0% visibility
      >
        {/* Duplicate content for smooth looping */}
        <div className="flex">
          {wins.map((item, index) => (
            <div className="flex items-center gap-2 pr-10">
              <div>{renderIcon(item.icon)}</div>
              <div className="flex flex-col">
              <span className="text-sm font-semibold " key={index}>{item.amount} AED</span>
              <span className='text-xs text-gray-400'>RaffleId: {item.id}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex">
        {wins.map((item, index) => (
            <div className="flex items-center gap-2 pr-10">
              <div>{renderIcon(item.icon)}</div>
              <div className="flex flex-col">
              <span className="text-sm font-semibold " key={index}>{item.amount} AED</span>
              <span className='text-xs text-gray-400'>RaffleId: {item.id}</span>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default RollingLine;
