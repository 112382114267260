import React from 'react'

const AndCounting = () => {
  return (
    <div className="w-full p-2 ">
    <div className="flex flex-col  w-full p-4 font-semibold text-white rounded-lg shadow bg-[#FFC3C3]">
      <span className='mb-1 text-sm'>Total winners and counting...</span>
      <span className="flex justify-center w-full py-2 text-xl font-bold text-gray-800 bg-white rounded-lg">998,642</span>
    </div>
  </div>
  )
}

export default AndCounting