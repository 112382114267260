import { useState } from "react"

const StatCard = ({ user }) => {
  const [extended, set_extended] = useState(false)
  console.log(user)

  return (
    <div
      key={user.uuId}
      className="p-4 mb-4 bg-white rounded-lg shadow-md"
    >
      {/* UUID and last action on top */}
      <div className="flex flex-col cursor-pointer">
        <div className="flex items-end justify-end w-full">
            <span className="mr-2 text-xs text-gray-500">Status: </span>
          {user.connected ? (
            <div className="flex items-center">
            <div className="w-2 h-2 mr-1 bg-green-500 rounded-full shadow shadow-green-500"></div>
            <span className="text-sm">Online</span>
        </div>
          ) : (
            <div className="flex items-center">
                <div className="w-2 h-2 mr-1 bg-red-500 rounded-full shadow shadow-red-500"></div>
                <span className="text-sm">Offline</span>
            </div>
          )}
        </div>
        <h2 className="text-lg font-bold text-gray-800">{user.uuId}</h2>
        <span className="mt-2 text-xs font-semibold text-gray-800">
          Last Action:{" "}
          <span className="text-base font-normal text-gray-600">
            {user.last_action}
          </span>
        </span>
      </div>

      <div
        onClick={() => set_extended(!extended)}
        className="mt-2 "
      >
        <h3 className="text-xs font-semibold text-gray-700">Actions:</h3>
        <ul
          className={`p-2 mt-1 overflow-hidden transition-all bg-gray-50 rounded ${
            extended ? "max-h-[100rem]" : "max-h-12"
          }`}
        >
          {user.actions.map((action, index) => (
            <li
              key={index}
              className="flex flex-col justify-between py-1 text-sm text-gray-800 border-b border-b-gray-100"
            >
              <span>{action.action}</span>
              <span className="text-xs text-gray-500">{action.time}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default StatCard
