import React, { useState, useEffect } from "react";
import StatCard from "./StatCard";

const UserStats = () => {
  const [users, setUsers] = useState([]); // Initialize with an empty array
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total_users, set_total_users] = useState(0)


  // Fetch users from server based on current page
  const fetchUsers = async (page) => {
    try {
      const response = await fetch(process.env.REACT_APP_SERVER_URL + `/api/users?page=${page}`);
      const data = await response.json();
    //   const reversedUsers = (data.users || []).reverse();

      setUsers(data.users); // Set the reversed array to state
      setTotalPages(data.totalPages || 1);
      set_total_users(data.total_users)
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Fetch users when the component mounts or the page changes
  useEffect(() => {
    fetchUsers(page);
  }, [page]);



  return (
    <div className="container p-4 mx-auto">
        <div className="flex items-center w-full p-2 mb-2 bg-white rounded shadow">
            <span className="text-lg font-semibold">Unique Users : {total_users}</span>
        </div>
      {/* Check if users is defined and an array */}
      {users && users.length > 0 ? (
        users.map((user) => (
          <StatCard user={user} />
        ))
      ) : (
        <p className="text-gray-700">No users found.</p>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4">
        <button
          className="px-4 py-2 text-white bg-blue-500 rounded"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          Previous
        </button>

        <span className="text-gray-700">Page {page} of {totalPages}</span>

        <button
          className="px-4 py-2 text-white bg-blue-500 rounded"
          disabled={page === totalPages}
          onClick={() => setPage(page + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UserStats;
