 export default async function trackUserAction( action, key_action) {
    const uuId = localStorage.getItem('uuId') || 'no_id'
    try {
      const response = await fetch(process.env.REACT_APP_SERVER_URL+'/api/track', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uuId, action, key_action }),
      });
  
      if (response.ok) {
        console.log('User action tracked');
      } else {
        console.error('Failed to track user action');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  


  