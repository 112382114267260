import React, {useContext, useState} from "react"
import trackUserAction from "../../../providers/track_action";

const Details = ({setActiveComponent}) => {

  const [button, setbutton] = useState('CONTINUE')
  return (
    <div className='flex flex-col w-full '>
      <span className='text-lg font-bold '>Personal Details</span>


      <div className="flex flex-col">
        <span className='text-sm font-semibold text-gray-700'>FULL NAME</span>
        <span className='text-xs text-gray-500'>Please enter all your details as they appear on your identity document. This information will be validated during the transfer of winnings.</span>
        <input type="text" name="" id="" className='p-2 mt-2 border rounded-lg focus:outline-prim_purple ' placeholder='Name'/>
      </div>

      <div className="flex flex-col">
        <span className='mt-4 text-sm font-semibold text-gray-700 uppercase'>Mobile Phone number</span>
          <input type="text" name="" id="" className='p-2 mt-2 border rounded-lg focus:outline-prim_purple ' placeholder='+971'/>
      </div>

      <div className="flex flex-col">
        <span className='mt-4 text-sm font-semibold text-gray-700'>EMAIL ADDRESS</span>
        <span className='text-xs text-gray-500'>Ticket information and invoices will be shared over email. Please ensure that your email is correct for seamless communication.</span>
        <input type="text" name="" id="" className='p-2 mt-2 border rounded-lg focus:outline-prim_purple ' placeholder='Email'/>
      </div>
      <button onClick={()=>{trackUserAction('User Went to Card Input', false);setbutton(<div className="cinputLoader"></div>); setTimeout(()=>setActiveComponent('Card'),2000)}} className="w-full p-2 mt-4 text-lg font-bold text-white rounded-full shadow bg-prim_purple">{button}</button>
      <span className="mt-2 text-xs text-gray-500">By finalizing your purchase through the Check Out button, you agree to Mahzooz  <span className="text-prim_blue">Term and conditions</span>. All payments will be deducted from your accounts in AED and inclusive of VAT</span>
   
    </div>
  )
}

export default Details