import React from 'react'
import { Link } from 'react-router-dom'
import trackUserAction from '../../../providers/track_action'


const LastWinComponent = ({icon, color, won, raffle, link}) => {
  return (

    <Link onClick={()=>{trackUserAction(`User Selected ${link} game`, false)}} to={`/Game${link}`} className="relative flex w-full h-20 mb-2 overflow-hidden rounded-lg shadow">
        <div  className={` h-full flex w-1/4 items-center relative justify-center `}>

        <svg className='absolute h-full opacity-50 2-full'  viewBox="0 0 128 88" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99999 88H67.1183C70.197 88 73.062 86.4263 74.7136 83.8281L128 0H9C4.02944 0 0 4.02944 0 9V79C0 83.9706 4.02943 88 8.99999 88Z" fill={color}/>
</svg>
        <svg className='absolute h-full 2-full scale-y-[-1] opacity-50'  viewBox="0 0 128 88" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99999 88H67.1183C70.197 88 73.062 86.4263 74.7136 83.8281L128 0H9C4.02944 0 0 4.02944 0 9V79C0 83.9706 4.02943 88 8.99999 88Z" fill={color}/>
</svg>



            {icon}
        </div>
        <div className="z-20 flex flex-col flex-shrink-0 py-2 ml-2 justify-evenly">
            <span className='text-xs font-semibold'>LATEST WIN</span>
            <span className='text-xl font-semibold'>{won} AED</span>
            <span className='text-xs font-semibold'>Raffle Id: # {raffle}</span>
        </div>
        <div className="flex flex-col items-end justify-end w-full p-2">
            <button style={{ backgroundColor: color }} className={`p-2 px-4 shadow text-sm  text-white rounded-lg`}>Try Now</button>
        </div>
    </Link>

  )
}

export default LastWinComponent