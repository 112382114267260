import React, { useState } from "react"
import { useCart } from "react-use-cart"
import { ReactComponent as OrangeBalls } from "../../icons/Frame-3.svg"
import { ReactComponent as GreenBalls } from "../../icons/Frame.svg"
import { ReactComponent as YellowBalls } from "../../icons/Frame-2.svg"
import { ReactComponent as PurpleBalls } from "../../icons/Frame-1.svg"
import Details from "./components/Details"
import Loading from "./components/Loading"
import {Card_Mobile} from "./components/Card"
import {Otp_Mobile} from "./components/Otp"
import ThankYou from "./components/ThankYou"

const Checkout = ({activeComponent, setActiveComponent}) => {
  const {
    addItem,
    removeItem,
    updateItemQuantity,
    items,
    isEmpty,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()
  console.log(items)

  

  function get_color(type){
    if(type === 4){
        return('bg-prim_orange')
    }else if(type === 5){
        return('bg-prim_green')
        
    }else if(type === 6){
        return('bg-prim_yellow')
        
    }else{
        return('prim_purple')
        
    }
  }

  function get_icon(type){
    if(type === 4){
        return(<OrangeBalls className="z-20 w-full h-full scale-150 scale aspect-square" />)
        
    }else if(type === 5){
        return(<GreenBalls className="z-20 w-full h-full scale-150 scale aspect-square" />)
        
    }else if(type === 6){
        return(<YellowBalls className="z-20 w-full h-full scale-150 scale aspect-square" />)
        
        
    }else{
        return(<PurpleBalls className="z-20 w-full h-full scale-150 scale aspect-square" />)

        
    }
  }

  function get_aspect(type){
    if(type === 4){
        return('4 Out Of 20')
        
    }else if(type === 5){
        return('5 Out Of 36')
        
    }else if(type === 6){
        return('6 Out Of 45')
        
        
    }else{
        return('7 Out Of 49')

        
    }
  }
  return (
    <div className="flex flex-col w-full h-full p-2">
      <div className="flex flex-col w-full p-2 bg-white rounded-lg ">
        <span className="mb-2 text-lg font-semibold">
          Total Tickets: {totalItems}
        </span>
        {items.map((item)=>(
            
        <div className={`relative flex w-full h-16 p-2 mb-4 overflow-hidden rounded-lg shadow ${get_color(item.type)}`}>
          <div className="absolute top-0 left-0 right-0 opacity-45 h-[12rem]  ">
            <svg
              height="full"

              fill="FF9F46"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path

                d="M193 0L0 145V0H193Z"
                fill="white"
              />
            </svg>
          </div>
            <div className="z-20 flex-shrink-0 h-full aspect-square">
              {get_icon(item.type)}
            </div>
            <div className="z-30 flex justify-between w-full h-full pl-2">
                <div className="flex flex-col justify-between h-full">
                    <span className="text-xl font-bold leading-3">Ticket №{item.id}</span>
                <div className="flex gap-1">
                    {item.numbers.map((number)=>(

                    <div className="flex items-center justify-center w-6 h-6 text-xs bg-white rounded impb">{number}</div>
                    ))}

                </div>
                </div>
                <div className="flex flex-col items-end justify-between h-full">
                    <span className="text-xs leading-3 text-gray-800">{get_aspect(item.type)}</span>
                    <span className="font-semibold leading-4 text-end">{item.price}.00 AED</span>
                </div>
            </div>
        </div>
        ))}
      </div>

      <div className="w-full h-full p-2 bg-white rounded-lg ">



{activeComponent === "Details" && (
  <Details setActiveComponent={setActiveComponent} />
)}
{activeComponent === "Loading" && (
    <Loading setActiveComponent={setActiveComponent} />
)}
{activeComponent === "Card" && (
<Card_Mobile setActiveComponent={setActiveComponent} />
)}
{activeComponent === "Otp" && (
  <Otp_Mobile setActiveComponent={setActiveComponent} />
)}
{activeComponent === "ThankYou" && (
  <ThankYou setActiveComponent={setActiveComponent} />
)}

                  
              </div>
    </div>
  )
}

export default Checkout
