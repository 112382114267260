import React, {useContext} from "react"
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaMinus,
  FaPlus,
  FaTrash,
  FaRegCreditCard,
} from "react-icons/fa"

const Loading = ({setActiveComponent}) => {

  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-52">
      <img src="/assets/img/logo/logo.png" className="w-32" alt="" />
      <span className="mt-6 loader"></span>
    </div>
  )
}

export default Loading
