import React from 'react'
import { Link } from 'react-router-dom'
import trackUserAction from '../../../providers/track_action'

const GameComponent = ({icon, color, text, price, prize, left, from,link}) => {
  return (
    <Link onClick={()=>{trackUserAction(`User Selected ${link} game`, false)}} to={`/Game${link}`} style={{ backgroundColor: color }} className={`relative flex  flex-col items-center overflow-hidden rounded-lg` }>

    <div className="absolute top-0 left-0 right-0 opacity-45 h-[12rem] ">
    <svg width="full"  height="full" viewBox="0 0 full full" fill="FF9F46" xmlns="http://www.w3.org/2000/svg">
    <path d="M193 0L0 145V0H193Z" fill="white"/>
    </svg>

    </div>

    {icon}
    <div className="flex flex-col items-center p-1 px-4 mb-2 bg-white bg-opacity-35 rounded-xl">
    <span className={`relative   rounded-full text-sm text-${text} font-semibold`}>Tickets Left</span>
    <span className={`relative   rounded-full text-sm text-${text} font-semibold`}>{left} <span className='text-xs'>/</span> {from}</span>

    </div>
    <span className={`relative text-sm text-${text} `}>Super Prize</span>
    <span className={`text-xl font-bold text-${text}`}>{prize} AED</span>
    {/* <div className="flex items-center p-1 px-2 mt-2 bg-white rounded bg-opacity-35">
      <svg

        stroke={text}
        fill={text}
        stroke-width="0"
        viewBox="0 0 1024 1024"
        height="0.75em"
        width="0.75em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm176.5 585.7l-28.6 39a7.99 7.99 0 0 1-11.2 1.7L483.3 569.8a7.92 7.92 0 0 1-3.3-6.5V288c0-4.4 3.6-8 8-8h48.1c4.4 0 8 3.6 8 8v247.5l142.6 103.1c3.6 2.5 4.4 7.5 1.8 11.1z"></path>
      </svg>
      <span className={`ml-1 text-xs text-${text}`}>01:12:44</span>
    </div> */}
    <div className="w-full p-2">
      
    <div className="flex justify-center w-full py-2 mb-1 bg-white rounded-lg items-ce">
      <div  className="flex items-center justify-center w-full">
        <span className="text-sm font-semibold">Play | {price} AED</span>
      </div>
    </div>
    </div>
  </Link>
  )
}

export default GameComponent