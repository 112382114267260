import React from 'react';
import { motion } from 'framer-motion';
import Countdown from 'react-countdown';


const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <span className="text-5xl text-white">We are Live!</span>;
  } else {
    return (
      <div className="text-center text-white">
        <div className="text-3xl font-bold ">
          {days}d {hours}h {minutes}m {seconds}s
        </div>
      </div>
    );
  }
};


const WaveBackground = () => {
  const targetDate = new Date('2024-09-18T20:00:00').getTime(); 

  return (
    <div className="relative w-full h-full overflow-hidden">

      <div className="absolute flex items-center p-1 bg-white rounded-full bottom-28 right-4">
        <div className="w-2 h-2 bg-red-500 rounded-full"></div>
        
        <span className='ml-1 text-xs font-black text-red-500'>Live</span>
      </div>
      {/* First Wave */}
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="absolute bottom-0 left-0 w-full h-auto"
        initial={{ y: 0 }}
        animate={{ y: [0, 20, 0] }}
        transition={{
          repeat: Infinity,
          duration: 8,
          ease: 'easeInOut',
        }}
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#ff6b6b', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#f06595', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <path
          fill="url(#gradient)"
          fillOpacity="1"
          d="M0,256L48,245.3C96,235,192,213,288,186.7C384,160,480,128,576,122.7C672,117,768,139,864,160C960,181,1056,203,1152,186.7C1248,171,1344,117,1392,90.7L1440,64V320H0Z"
        ></path>
      </motion.svg>

      {/* Second Wave */}
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="absolute bottom-0 left-0 w-full h-auto"
        initial={{ y: 0 }}
        animate={{ y: [20, 0, 20] }}
        transition={{
          repeat: Infinity,
          duration: 10,
          ease: 'easeInOut',
        }}
      >
        <defs>
          <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#22d3ee', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#06b6d4', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <path
          fill="url(#gradient2)"
          fillOpacity="1"
          d="M0,64L48,90.7C96,117,192,171,288,186.7C384,203,480,181,576,154.7C672,128,768,96,864,101.3C960,107,1056,149,1152,170.7C1248,192,1344,192,1392,192L1440,192V320H0Z"
        ></path>
      </motion.svg>

      {/* Third Wave */}
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="absolute bottom-0 left-0 w-full h-auto opacity-60"
        initial={{ y: 0 }}
        animate={{ y: [0, 10, 0] }}
        transition={{
          repeat: Infinity,
          duration: 12,
          ease: 'easeInOut',
        }}
      >
        <defs>
          <linearGradient id="gradient3" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#e9d8fd', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#fbc2eb', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <path
          fill="url(#gradient3)"
          fillOpacity="1"
          d="M0,192L48,202.7C96,213,192,235,288,234.7C384,235,480,213,576,202.7C672,192,768,192,864,186.7C960,181,1056,171,1152,160C1248,149,1344,139,1392,133.3L1440,128V320H0Z"
        ></path>
      </motion.svg>

      {/* Overlay Text */}
      <div className="relative z-10 p-6 text-center top-20">
        <h1 className="text-xl font-bold text-white ">Watch The Live Draw!</h1>
        <Countdown date={targetDate} renderer={CountdownRenderer} />
        <p className="text-lg text-white">18.09 at 20:00</p>
      </div>
    </div>
  );
};

export default WaveBackground;
