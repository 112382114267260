import { useState, useEffect } from 'react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const useHistoryParams = () => {
  const [params, setParams] = useState(new URLSearchParams(history.location.search));

  useEffect(() => {
    const unlisten = history.listen(({ location }) => {
      setParams(new URLSearchParams(location.search));
    });

    return () => {
      unlisten();
    };
  }, []);

  const updateParams = (newParams) => {
    const currentParams = new URLSearchParams(history.location.search);
    Object.keys(newParams).forEach(key => {
      if (newParams[key] === null) {
        currentParams.delete(key);
      } else {
        currentParams.set(key, newParams[key]);
      }
    });
    history.replace({ search: currentParams.toString() });
  };

  return { params, updateParams };
};

export default useHistoryParams;
