import React, { createContext, useContext, useState, useEffect } from 'react';

import ReactPixel from 'react-facebook-pixel';
import useHistoryParams from './useHistoryParam';


const PromoContext = createContext();

export const usePromo = () => useContext(PromoContext);

export const PromoProvider = ({ children }) => {
  const { params, updateParams } = useHistoryParams();


  useEffect(() => {
    const pixelParam = params.get('pixelId');


    if (pixelParam) {
      ReactPixel.init(pixelParam);
      console.log('Facebook Pixel initialized with:', pixelParam);
    }




    // Remove params from URL if they exist
    if (pixelParam ) {
      updateParams({ pixelId: null});
    }
  }, [params, updateParams]);

  return (
    <PromoContext.Provider>
      {children}
    </PromoContext.Provider>
  );
};
