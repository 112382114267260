import React from 'react'
import LastWinComponent from './LastWinComponent'
import { ReactComponent as OrangeBalls } from "../../../icons/Frame-3.svg"
import { ReactComponent as GreenBalls } from "../../../icons/Frame.svg"
import { ReactComponent as YellowBalls } from "../../../icons/Frame-2.svg"
import { ReactComponent as PurpleBalls } from "../../../icons/Frame-1.svg"


const LastWins = () => {
  return (
    <div className='flex flex-col p-2'>
        <LastWinComponent link={4} won={'12,500'} raffle={41517} color={'#FF8310'} icon={<OrangeBalls className='z-20 p-4' />} />
        <LastWinComponent link={5} won={'41,250'} raffle={41517} color={'#00FF83'} icon={<GreenBalls className='z-20 p-4' />} />
        <LastWinComponent link={6} won={'37,600'} raffle={41517} color={'#FFFC00'} icon={<YellowBalls className='z-20 p-4' />} />
        <LastWinComponent link={7} won={'125,000'} raffle={41517} color={'#D300FF'} icon={<PurpleBalls className='z-20 p-4' />} />
</div>
  )
}

export default LastWins