import React, { useState, useEffect } from 'react';



const CountdownComponent = () => {

    const [countdown, setCountdown] = useState(59); // Initial countdown time in seconds
    const [sendCodeMessage, setSendCodeMessage] = useState(false); // State to control when to send another code

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);

            return () => clearTimeout(timer);
        } else {
            // Countdown reaches 0, trigger send code message
            setSendCodeMessage(true);

            // Reset countdown after 56 seconds
            const resetTimer = () => {
                if(countdown === 0){
                    setCountdown(59);
                    setSendCodeMessage(false); // Reset send code message state
                }
            } 

            return () => clearTimeout(resetTimer);
        }
    }, [countdown]);
    const resetTimer = () => {
        if(countdown === 0){
            setCountdown(59);
            setSendCodeMessage(false); // Reset send code message state
        }
    } 
    return (
        <div onClick={resetTimer} className="mt-4 text-sm text-white text-start">
            {sendCodeMessage ? (
                <p>{'Send code again'}</p>
            ) : (
                <p>{'Send new code in '} {countdown} {'seconds'}</p>
            )}
        </div>
    );
};

export default CountdownComponent;