import React from 'react'

import { ReactComponent as OrangeBalls } from "../../icons/Frame-3.svg"

import TicketGame from './components/TicketGame'
const Game4 = () => {
  return (
    <div className='flex-col w-full p-2 felx'>
      <div className="flex items-center w-full">
        <div className="w-16 h-16 bg-[#FF8310] flex items-center justify-center rounded-xl ">
          <OrangeBalls className='w-12 h-12' />
        </div>
        <span className='ml-2 text-xl font-semibold'>"4 Out Of 20"</span>
      </div>
      <div className="flex flex-col w-full mb-6">
        <span className='mt-4 text-3xl font-bold'>100,000 AED</span>
        <div className="flex items-center mt-2">
          <span className='text-gray-400 '>RaffleId: # 41211</span>
          <span className='flex items-center ml-2 font-semibold '><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.75em" width="0.75em" xmlns="http://www.w3.org/2000/svg"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm176.5 585.7l-28.6 39a7.99 7.99 0 0 1-11.2 1.7L483.3 569.8a7.92 7.92 0 0 1-3.3-6.5V288c0-4.4 3.6-8 8-8h48.1c4.4 0 8 3.6 8 8v247.5l142.6 103.1c3.6 2.5 4.4 7.5 1.8 11.1z"></path></svg> 16 September 2024, 20:00</span>
        </div>
      </div>
      <TicketGame type={4} price={5} out={4} of={20} color={'prim_orange'} />
    </div>
  )
}

export default Game4