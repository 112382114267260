import React from 'react'

const OurWinnder = () => {
  return (
    <div className="w-full p-2 ">
    <div className="flex flex-col w-full p-4 bg-white rounded-lg">
      <span className="w-full mb-2 text-lg font-semibold">Meet our lucky winners</span>
    <iframe
    width="100%"
    height="200"
    src={`https://www.youtube.com/embed/9X-CcARn5QE`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded YouTube Video"
    className="rounded-lg"
  ></iframe>
    </div>
  </div>
  )
}

export default OurWinnder